// WaGen___HASHKEY__91731158_2021-08-25 16:00:51 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function yhJbxx_add(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：yhbh : String
*/
export function yhJbxx_update(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：yhbh : String
*/
export function yhJbxx_remove(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function yhJbxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function yhJbxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function yhJbxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：yhbh : String
*/
export function yhJbxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function yhJbxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhJbxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //YhJbxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhJbxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function yhJbxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //YhJbxx
		}
	})
}

/* ---
按照配电编号递归查询所有节点所属用户
*/
export function yhJbxx_getJbxxByPdbh(jgbm,pdbh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getJbxxByPdbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				pdbh : pdbh //Integer
			},
			pagination : pagination
		}
	})
}

/* ---
统计连续三个月及以上未抄表算费的用户数量
*/
export function yhJbxx_getSynWsfyhCount(jgbm,dybm, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getSynWsfyhCount',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm //String
			}
		}
	})
}

/* ---
统计连续三个月及以上未抄表算费的用户信息
*/
export function yhJbxx_getYdhhBySynWsf(jgbm,dybm, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getYdhhBySynWsf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm //String
			},
			pagination : pagination
		}
	})
}

/* ---
 用电户号查询——按其他信息查询

 @param jgbm 机构编码
 @return 用户基本信息

*/
export function yhJbxx_getYdhhByJldhOrPdbh(jgbm,zzbh,zzcch,jldh,pdbh,ztch,ydlb, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getYdhhByJldhOrPdbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				zzbh : zzbh, //String
				zzcch : zzcch, //String
				jldh : jldh, //String
				pdbh : pdbh, //String
				ztch : ztch, //String
				ydlb : ydlb //String
			},
			pagination : pagination
		}
	})
}

/* ---
 用户基本信息模糊查询

 @param tqbh  卡本号
 @param phone 手机号
 @param zjbh  证件编号
 @param gjz   关键字
 @return

*/
export function yhJbxx_getYhjbxxMhcx(jgbm,gjz,phone,zjbh,tqbh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getYhjbxxMhcx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				gjz : gjz, //String
				phone : phone, //String
				zjbh : zjbh, //String
				tqbh : tqbh //Integer
			},
			pagination : pagination
		}
	})
}

/* ---
 通过配电编号查询用户信息

 @param pdbh
 @return 符合条件的用户信息list

*/
export function yhJbxx_getYdhhByPdbh(pdbh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getYdhhByPdbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				pdbh : pdbh //Integer
			},
			pagination : pagination
		}
	})
}

/* ---
 用电户号查询——按基本信息查询

 @param jgbm 机构编码
 @return 用户基本信息

*/
export function yhJbxx_getYdhhByBaseInfo(jgbm,dybm,tqbh,lxsj,zjbh,dqzt,yhmc,yhdz,yhkzsx,sspq,yhfl,lsbz,nsbz, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getYdhhByBaseInfo',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm, //String
				tqbh : tqbh, //Integer
				lxsj : lxsj, //String
				zjbh : zjbh, //String
				dqzt : dqzt, //String
				yhmc : yhmc, //String
				yhdz : yhdz, //String
				yhkzsx : yhkzsx, //String
				sspq : sspq, //String
				yhfl : yhfl, //String
				lsbz : lsbz, //String
				nsbz : nsbz //String
			},
			pagination : pagination
		}
	})
}

/* ---
 用电户号查询——异常档案查询

 @param jgbm 机构编码
 @param dybm 单元编码
 @return 用户基本信息

*/
export function yhJbxx_getYdhhByDayc(jgbm,dybm, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getYdhhByDayc',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm //String
			},
			pagination : pagination
		}
	})
}

/* ---
 特殊用电户查询——按特殊计量点信息查询  杨燕武  20190924

 @param jgbm  机构编码
 @param dybm  单元编码
 @param fjldh 父计量点号
 @param fsbz  发行标志
 @param jbflb 基本费类别
 @param jldbl 计量点变量
 @param ygddl 月固定电量
 @param zylx  资源类型
 @param ltbz  力调标志
 @return 用户基本信息，计量点信息

*/
export function yhJbxx_getYhxxByTsjld(jgbm,dybm,ltbz,jbflb,jldbl,ygddl,fsbz,zylx,fjldh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/getYhxxByTsjld',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm, //String
				ltbz : ltbz, //String
				jbflb : jbflb, //String
				jldbl : jldbl, //Integer
				ygddl : ygddl, //String
				fsbz : fsbz, //String
				zylx : zylx, //String
				fjldh : fjldh //String
			},
			pagination : pagination
		}
	})
}

/* ---
 返回机构信息为中文的用户信息

 @param yhbh
 @return

*/
export function yhJbxx_fetchOneYhJbxxByYhbh(yhbh, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/fetchOneYhJbxxByYhbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhbh : yhbh //String
			}
		}
	})
}

/* ---
 多条件用户筛选

 @param jgbm
 @param ydlbList   用电类别
 @param pdbhList   配电编号
 @param yhkzsxList 用户扩展属性
 @param tqbh
 @param lxsj
 @param yhmc
 @param yhdz
 @param yhbh
 @param jldh
 @param zjbh
 @param qf
 @return

*/
export function yhJbxx_filtrateUser(jgbm,dybm,ydlbList,pdbhList,yhkzsxList,tqbh,lxsj,yhmc,yhdz,yhbh,jldh,zjbh,qf, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/filtrateUser',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm, //String
				ydlbList : ydlbList, //List<String>
				pdbhList : pdbhList, //List<Integer>
				yhkzsxList : yhkzsxList, //List<String>
				tqbh : tqbh, //List<Integer>
				lxsj : lxsj, //String
				yhmc : yhmc, //String
				yhdz : yhdz, //String
				yhbh : yhbh, //String
				jldh : jldh, //String
				zjbh : zjbh, //String
				qf : qf //Integer
			},
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function yhJbxx_insertList(list, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<YhJbxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：yhbh : String
*/
export function yhJbxx_updateList(list, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<YhJbxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：yhbh : String
*/
export function yhJbxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmszhcx/zhcxschema/YhJbxx/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<YhJbxx>
		}
	})
}

